<template>

	<div>
		<van-nav-bar class="navbar" title="工资" fixed left-arrow @click-left="leftReturn" />
		<!-- 主体的列表们 -->
		<div class="head">
			<!-- <div class="verifyTime">
				确认时间：2020-12-12 12:45
			</div> -->
			<div class="bo-dy">
				<div style="text-align: center;"><img class="wamp" src="../../../assets/images/wamp.png" alt=""></div>
				<div class="money">{{actualAmount}}元</div>
				<div class="month">{{salaryTime}}工资总额</div>
				<div class="line"></div>
				<div style="height: 620px;">
					<div class="left">
						<div class="title">缓发总额：</div>
						<div class="title">底薪：</div>
						<div class="title">考勤：</div>
						<div class="title">通讯补助：</div>
						<div class="title">交通补助：</div>
						<div class="title">互助金：</div>
						<div class="title">年资：</div>
						<div class="title">社保：</div>
						<div class="title">补扣项：</div>
						<div class="title">出房重奖：</div>
						<div class="title">收房重奖：</div>
						<div class="title">出房佣金：</div>
						<div class="title">收房佣金：</div>
						<div class="title">考核底薪：</div>
						<div class="title">火房考核：</div>
						<div class="title">应发金额：</div>
						<div class="title">税：</div>
						<!--<div class="title">实发金额：</div>-->
						<div class="title">外部系数：</div>

					</div>
					<div class="right">
						<div class="moneydet">{{delayAmount}}元</div>
						<div class="moneydet">{{baseSalary}}元</div>
						<div class="moneydet">{{workCheck}}元</div>
						<div class="moneydet">{{communicationSubsidy}}元</div>
						<div class="moneydet">{{trafficSubsidy}}元</div>
						<div class="moneydet">{{mutualFund}}元</div>
						<div class="moneydet">{{seniority}}元</div>
						<div class="moneydet">{{socialSecurity}}元</div>
						<div class="mingxi" @click="wageHistoryDetail()">{{addtion}}元</div>
						<div class="moneydet">{{outBonus}}元</div>
						<div class="moneydet">{{receiveBonus}}元</div>
						<div class="moneydet">{{reAchievements}}元</div>
						<div class="moneydet">{{achievements}}元</div>
						<div class="moneydet">{{baseSalaryAssessment}}元</div>
						<div class="moneydet">{{kitchen}}元</div>						
						<div class="moneydet">{{payableAmount}}元</div>
						<div class="moneydet">{{tax}}元</div>
						<!--<div class="moneydet">{{actualAmount}}元</div>-->
						<div class="moneydet">{{externalEoefficient}}</div>
					</div>
				</div>
				<div class="line"></div>
				<div class="real">
					<div class="realtitle">实发：</div>
					<div class="realmoneydet">{{actualAmount}}元</div>
				</div>
				<div v-if="salaryStatus == '1'" class="bottomNavigationB" @click="confrim">确认</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		queryBaseData,
		wageList,
		confirmWage,
		wageDetailList
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil,
		getStaffId
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				show: false,
				showPicker: false,
				isOccupationShow: false,
				loading: false,
				finished: false,
				certificateType: '',
				isOccupationShow: false,
				defaultIndex: '',
				swi: true,
				isLoading: false,
				totalPage: '',
				paylist: [],
				actualAmount: '',//实际金额
				salaryStatus: '', //工资状态
				addTime: '',//时间
				salaryTime: '',//工资时间
				socialSecurity: '',//社保
				seniority:'',//年资
				workCheck:'',//考勤
				baseSalary:'',//底薪
				communicationSubsidy:'',//通讯补助
				trafficSubsidy:'',//交通补助
				mutualFund:'',//互助金
				addtion:'',//补扣项
				tax:'',//税
				payableAmount:'',//应发
				salaryHistoryId:'',
				delayAmount:0,//缓发金额
				externalEoefficient:0,//外部系数
				receiveBonus:0,
				outBonus:0,
				reAchievements:0,  //收房佣金
				kitchen:0,//火房考核
				baseSalaryAssessment:0,
				actualAmount:0,
				achievements:0,//出房佣金



			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {
			this.wageDetailList()
			// this.initData();
			// this.initDicData();
		},
		//初始化
		mounted() {
			// this.certificateType = this.columns[0]
		},
		methods: {
			confrim() {
				let that = this
				let initData = {}
				initData.staff_id = getStaffId() //userid
				initData.salaryHistoryDetail_id = that.$route.query.salaryHistory_id
				confirmWage(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						// Toast('走通了，但是确认之后呢')
                        that.leftReturn()
					})
				})
			},
			//工资明细初始化接口
			wageDetailList() {
				let that = this
				let initData = {};
				initData.salaryHistory_id = that.$route.query.salaryHistory_id
				wageDetailList(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response)
						that.salaryStatus = response.data.data.salaryStatus
						that.socialSecurity = response.data.data.socialSecurity  //社保
						that.actualAmount = response.data.data.actualAmount
						that.addTime = response.data.data.addTime
						that.salaryTime = response.data.data.salaryTime
						that.seniority = response.data.data.seniority
						that.workCheck = response.data.data.workCheck
						that.baseSalary = response.data.data.baseSalary
						that.communicationSubsidy = response.data.data.communicationSubsidy
						that.trafficSubsidy = response.data.data.trafficSubsidy
						that.mutualFund = response.data.data.mutualFund
						that.addtion = response.data.data.addtion
						that.tax = response.data.data.tax
						that.payableAmount = response.data.data.payableAmount
						
						that.salaryHistoryId = response.data.data.salaryHistoryId
						that.externalEoefficient=response.data.data.externalEoefficient?response.data.data.externalEoefficient:0
						that.receiveBonus=response.data.data.receiveBonus?response.data.data.receiveBonus:0
						that.outBonus=response.data.data.outBonus?response.data.data.outBonus:0
						that.reAchievements=response.data.data.reAchievements?response.data.data.reAchievements:0
						that.kitchen=response.data.data.kitchen?response.data.data.kitchen:0
						that.baseSalaryAssessment=response.data.data.baseSalaryAssessment?response.data.data.baseSalaryAssessment:0
						that.actualAmount=response.data.data.actualAmount?response.data.data.actualAmount:0
						that.achievements=response.data.data.achievements?response.data.data.achievements:0
						that.delayAmount=response.data.data.delayAmount?response.data.data.delayAmount:0
					})
				})
			},
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			default () { //默认值
			},
			
			//查询补扣项明细
			wageHistoryDetail() {
				this.$router.push({
					name: 'wageHistoryDetail',
					query: {
						salaryHistory_id: this.salaryHistoryId
					}
				})
			},
			
			
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: rgb(248, 248, 248);
	}

	.head {
		margin-top: 60px;
	}

	.verifyTime {
		font-size: 12px;
		color: #ACACAC;
		text-align: right;
		padding-right: 20px;
		padding-bottom: 10px;
	}

	.bo-dy {
		width: 90%;
		height: 650px;
		background-color: white;
		border-radius: 8px;
		margin: 0 auto;
	}

	.wamp {
		width: 60px;
		margin-top: 30px;
	}

	.money {
		text-align: center;
		color: #FF5D3B;
		font-weight: 600;
		font-size: 30px;
	}

	.month {
		font-size: 12px;
		color: #ACACAC;
		text-align: center;
		margin-top: 10px;
	}

	.line {
		width: 100%;
		border-top: #f8f8f8 2px solid;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.left {
		float: left;
		margin-left: 20px;
	}

	.title {
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 4px;
	}

	.right {
		float: right;
		margin-right: 20px;
	}

	.moneydet {
		font-size: 14px;
		margin-bottom: 4px;
		color: gray;
		font-weight: 600;
	}

	.real{
		width: 100%;
		height: 40px;
		background-color: white;
		border-radius: 0 0 8px 8px;
	}

	.realtitle{
		font-size: 14px;
		font-weight: 600;
		margin-left: 20px;
		float: left;
	}

	.realmoneydet{
		font-size: 14px;
		color: gray;
		float: right;
		margin-right: 28px;
		color: #FF5D3B;
		font-weight: 600;
	}

	.bottomNavigationB {
		height: 50px;
		width: 100%;
		font-size: 16px;
		align-items: center;
		border-radius: 7px;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
		line-height: 50px;
		font-family: PingFangSC-Semibold;
		text-align: center;
		margin-top: 45px;
	}
	.mingxi {
		
		
		color: rgba(0, 85, 255, 1.0);
		font-size: 14px;
		
		text-decoration: underline;
		

		
		margin-bottom: 4px;
		
		font-weight: 600;
		
		
	}
</style>
